import React, { Component } from 'react';
import Frame from "../../components/frame";
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import FTINU from "../../contracts/FTINU.json";
import getWeb3 from "../../getWeb3";
import { BigNumber } from "bignumber.js";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:true,
      web3: null,
      accounts: null,
      contract: null,
      contractAddress: null,
      YourAddress:'-----------',
      YourAddressfull:null,
      sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
      userInfo:{},      
      user_referer:'-----------',
      userBalanceOf:0,
      currentPrice:0,
      balance:0,
      balanceEthVal:0,
      amount:'',
      totalAmount:'',
      availableStake:0,
      staking:0,
      totalTokenSupply:0,
      table_data:[],
      per:0
    }
  }

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      setInterval(async()=>{
        const accounts = await web3.eth.getAccounts();
       
        if(this.state.accounts !== accounts[0]){
        // Get the contract instance.
        const networkId = await web3.eth.net.getId();
        //alert(networkId);
        //if(networkId !== 56) throw "Please connect Mainnet"; 
        const deployedNetwork = FTINU.networks;
        const instance = new web3.eth.Contract(
          FTINU.abi,
          deployedNetwork && FTINU.address,
        );   
        
        this.setState({ web3, accounts:accounts[0], contract: instance, contractAddress:FTINU.address}, this.fetchData);
        }
     },1000);

    } catch (error) {
      // Catch any errors for any of the above operations.
      // alert(
      //   error
      // );
      this.setState({networkMain:false});
      console.error(error);
    }
  };

  fetchData = async() => {
    const { accounts, contract } = this.state;

    //console.log(this.state.contractAddress);

    let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
    let YourAddressfull = accounts;
    
    let currentPrice = await contract.methods.buyPrice().call();
    currentPrice = this.state.web3.utils.fromWei(currentPrice, 'ether');

    let userBalanceOf = await contract.methods.userBalanceOf(accounts).call();
    userBalanceOf = (userBalanceOf / 1e8);
    

    let balance = await this.state.web3.eth.getBalance(accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');

    let availableStake = userBalanceOf;

    let staking = await contract.methods.getUserTotalStake(accounts).call();
    staking = (staking / 1e8);

    let totalTokenSupply = await contract.methods.totalTokenSupply().call();
    totalTokenSupply = (totalTokenSupply / 1e8);
    totalTokenSupply = parseFloat(totalTokenSupply).toFixed(2);  

    let per = ((totalTokenSupply / 100000000000000) * 100);
    per = parseFloat(per).toFixed(2);

    this.setState({
      YourAddress,
      YourAddressfull,
      currentPrice,
      userBalanceOf,
      balance,
      balanceEthVal,
      availableStake,
      staking,
      totalTokenSupply,
      per,
      loading:false
    },async()=>{
      const { accounts, contract } = this.state;
      let getUserDetails = await contract.methods.getUserDetails(accounts).call();
      let table_length = parseInt(getUserDetails[0]);
      let table_data = [];
  
      for(let i=0;i<table_length;i++){
        let stake = await contract.methods.stakeOf(accounts,i).call();
        stake = (stake /1e8);
  
        let roi = await contract.methods.getUserDividend(accounts,i).call();
        let roi_value = roi;
        roi = (roi /1e8);

        let temp_data = {index:i,stake:stake,roi:roi};
        if(roi_value>0){
          table_data.push(temp_data);
        }
      }
      
      this.setState({table_data});
    })
    
  }

  doBuy= async () => {
    //const weiValue = this.state.web3.utils.toWei('1', 'ether');
    //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
    const { accounts, contract } = this.state;
    let sponsor_address = this.state.sponsor_address;
    
    if(!sponsor_address){
      this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
      this.setState({sponsor_addressError:true});
      return false;
    }

    if(!this.state.amount){
      this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
      this.setState({amountError:true});
      return false;
    }
    
    let balance = await this.state.web3.eth.getBalance(this.state.accounts);
    let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
    let amountBNB = this.state.totalAmount;
    if(sponsor_address){
      //if(balanceEthVal >= amountBNB){
        try {
          this.setState({loading:true});
          this.state.contract.events.BuyToken((err, eventResult) => {
            console.log('eventResult',eventResult);
            if (!err) {
              let uerAddress = this.state.accounts;
              if(eventResult.returnValues[0] == uerAddress){
                this.fetchData();
                this.setState({loading:false});
                this.props.enqueueSnackbar("Buy Token Successfully!",{ variant: 'success' });                
              }              
            }else{
              console.log(err);
              this.setState({loading:false});
              this.props.enqueueSnackbar('Some Network Error Occurred!');              
            }
          });

          let weiValue = this.state.web3.utils.toWei(amountBNB.toString(), 'ether');
          let invest = await this.state.contract.methods.buyToken(sponsor_address).send(
            {
              from: this.state.accounts,
              value:weiValue
            }
          );
          //console.log(invest);
        }
        catch(err) {
          this.setState({loading:false});
          if (err.message.includes("User denied transaction signature")) {
            // handle the "error" as a rejection
            this.props.enqueueSnackbar(err.message,{ variant: 'error' });
          }else{
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }          
      // }else{
      //   this.setState({loading:false});
      //   this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
      // }        
    }else{
      this.setState({loading:false});
      this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
    }
  }

  doStake = async () => {    
    try {
      this.setState({loading:true});
      this.state.contract.events.StakeToken((err, eventResult) => {
        console.log('eventResult',eventResult);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Token Stake Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!');              
        }
      });
      
      let createStake = await this.state.contract.methods.createStake((this.state.userBalanceOf*1e8).toString()).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(createStake);
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    } 
  }

  doUnstake = async (index) => {    
    try {
      this.setState({loading:true});
      this.state.contract.events.UnStake((err, eventResult) => {
        console.log('eventResult',eventResult);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Token Unsake Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!');              
        }
      });
   
      let removeStake = await this.state.contract.methods.removeStake((index).toString()).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(removeStake);
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }

  doCompound = async (index) => {
    try {
      this.setState({loading:true});
      this.state.contract.events.CompoundStakeToken((err, eventResult) => {
        console.log('eventResult',eventResult);
        if (!err) {
          let uerAddress = this.state.accounts;
          if(eventResult.returnValues[0] == uerAddress){
            this.fetchData();
            this.setState({loading:false});
            this.props.enqueueSnackbar("Token Compound Successfully!",{ variant: 'success' });                
          }              
        }else{
          console.log(err);
          this.setState({loading:false});
          this.props.enqueueSnackbar('Some Network Error Occurred!');              
        }
      });

      let compound = await this.state.contract.methods.compound((index).toString()).send(
        {
          from: this.state.accounts,
          value:0
        }
      );
      //console.log(compound);
    }
    catch(err) {
      this.setState({loading:false});
      if (err.message.includes("User denied transaction signature")) {
        // handle the "error" as a rejection
        this.props.enqueueSnackbar(err.message,{ variant: 'error' });
      }else{
        this.props.enqueueSnackbar(err,{ variant: 'error' });
      }          
    }
  }

  doCalculation=(e,_type)=>{
    var x = new BigNumber(e.target.value);
    if(_type==0){
        this.setState({
              amount:e.target.value,
              totalAmount:(x.isNaN() ? 0 : x.multipliedBy(this.state.currentPrice))
        })
    }else{

        var y = new BigNumber(this.state.currentPrice);
        this.setState({
              amount:(x.isNaN() ? 0 : x.dividedBy(y)),
              totalAmount:(x.isNaN() ? 0 : x)
        })
    }
  }


    render() {
      return (
        <Frame withHeader={true} withFooter={true}>
          {this.state.loading ? (
            <div class="loader2_bg">
              <div className="loader2"></div>
            </div>
          ) : (null)}
         <section className="banner_section pt_50 pb_50">
          <div className="container">
            <div className="banner_text text-center middle_text">
              <h1>FTINU Token </h1>
              <p>Current FTINU Rate: <strong>{this.state.currentPrice} BNB 
              {/* ($24) */}
              </strong></p>
              <div className="mt_10">
                <a target="_blank" className="grad_btn" href="#">Our Whitepaper <i className="fa fa-download" ></i></a>
              </div> 
            </div>
          </div>
        </section>
        <section className="pb_50">
          <div className="container">
            <div className="sm_container">
              <div className="row cus_row">
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <img className="Personal_icon" src="img/wallet.png" alt="" />
                    <h4>Your Wallet</h4>
                    <h5>{this.state.YourAddress}</h5>
                  </div>
                </div> 
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <img className="Personal_icon" src="img/balance.png" alt="" />
                    <h4>Your Balance</h4>
                    <h5>{parseFloat(this.state.balanceEthVal).toFixed(5)} BNB/ {this.state.userBalanceOf} FTINU</h5>
                  </div>  
                </div> 
              </div>
            </div>
          </div>
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="sm_container">
              <div className="row">
                <div className="col-md-12 buy_sell_box">
                  <h5 className="gradient_text">Buy FTINU</h5>
                  <hr />
                  <div className="row">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">FTINU Referal</label>
                        <input className="cus_input" type="text" 
                        value={this.state.sponsor_address}
                        onChange={(e)=>this.setState({sponsor_address:e.target.value})}  />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">Current Price (BNB)</label>
                        <input className="cus_input" type="text" readOnly value={this.state.currentPrice} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">FTINU Token</label>
                        <input className="cus_input" type="text" 
                          value={this.state.amount}
                          onChange={(e)=>this.doCalculation(e,0)}

                        />
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                      <div className="form-group">
                        <label className="white_label">Total Amount (BNB)</label>
                        <input className="cus_input" type="text" 
                          value={this.state.totalAmount}
                          onChange={(e)=>this.doCalculation(e,1)}
                         />
                      </div>
                    </div>
                  </div>
                  <div className="text-center">
                    <button disabled={this.state.loading} className="grad_btn" onClick={this.doBuy}>Buy FTINU</button>
                  </div>
                </div>
                
              </div>
            </div>
            </div>
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="all_heading text-center">
              <h2><span>Referral </span> Stake</h2>
            </div>
            <div className="sm_container">
              <div className="row cus_row">
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Available for Stake</h4>
                    <h5>{this.state.availableStake}</h5>
                    <button disabled={this.state.loading} type="button" class="btn stake_p_a btn-outline-success btn-sm" onClick={this.doStake}>Stake</button>
                  </div>
                </div> 
                <div className="col-md-6 col-sm-6 col-6">
                  <div className="Personal_Details_inner">
                    <h4>Staking</h4>
                    <h5>{this.state.staking}</h5>
                  </div>  
                </div> 
              </div>
              {/* <div className="text-center mt_20 mb_20">
                <button className="grad_btn">Withdrawal</button>
              </div> */}
            </div>
            <br />
            <div className="sm_container">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr>
                      <th>Stake</th>
                      <th>ROI</th>
                      <th>Unstake</th>
                      <th>Compound</th>
                     </tr>
                  </thead>
                  <tbody>
                    {
                      
                      this.state.table_data.length ? (
                        
                        this.state.table_data.length>0 ? (


                          this.state.table_data.map((val, index)=>{
                          let class_name = 'lebel_'+val.index;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                    
                          return (
                            <tr key={`cl${index}`} className={class_name}>
                              <td>{val.stake}</td>
                              <td>{val.roi}</td>
                              <td><button  type="button" class="btn btn-outline-success btn-sm btn-block" onClick={()=>this.doUnstake(val.index)}>Unstake</button></td>
                              <td><button  type="button" class="btn btn-outline-danger btn-sm btn-block" onClick={()=>this.doCompound(val.index)}>Compound</button></td>
                            </tr>
                          )
                        })
                          
                        ):(null)
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">No Data Available!</td>
                      </tr>
                    )}                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section className="pb_50">
          <div className="container">
            <div className="all_heading text-center">
              <h2><span>FTINU </span> Token Details</h2>
            </div>
            <div className="sm_container">
              {/* <p className="text-center mb-0">FTINU Master Contract Address : <span className="theme_color">0xf13503949DCaB8dCdEb09e0Db78E411537c692a0</span></p> */}
              <p className="text-center">FTINU Token Contract :               
                <a href="https://bscscan.com/address/0x3F9b9423c2f1A3A2c2d618Cc087F4551DE126C03" target="_blank">
                  <span className="theme_color"> 0x3F9b9423c2f1A3A2c2d618Cc087F4551DE126C03</span>
                </a>                
              </p>
              
            </div>
            <hr />
            <div className="sm_container">
              <div className="all_heading text-center">
                <h2><span>FTINU </span> Tokenomics</h2>
              </div>
              <div className="row">
                  <div className="col-md-6 co-lg-6 middle_text">
                    <ul class="progress-info">
                      <li>
                        <span><strong>Current Supply</strong></span>
                        <span>{this.state.totalTokenSupply}</span>
                      </li>
                      <li class="text-right">
                        <span><strong>Max Supply</strong>
                        </span><span>1,000,000,000,000</span>
                      </li>
                    </ul>
                    <div class="text-center mb-3">
                      <span class="round-1">{this.state.per}%</span>
                    </div>
                    <div className="progress" style={{height: "15px"}}>
                      <div className="progress-bar progress-bar-striped progress-bar-animated" style={{width: `${this.state.per}%`}}></div>
                    </div>
                      
                  </div>
                  <div className="col-md-6 co-lg-6">
                       
                      <table className="table sm_table">
                      <tbody>
                          <tr>
                            <td>Current Supply</td>
                            <td>{this.state.totalTokenSupply} FTINU</td>
                          </tr>
                          <tr>
                            <td>Max Supply</td>
                            <td>1,000,000,000,000 FTINU</td>
                          </tr>
                          <tr>
                            <td>Team in Circulation (10%)</td>
                            <td>100,000,000,000 FTINU</td>
                          </tr>
                          <tr>
                            <td>Marketing in Circulation (20%)</td>
                            <td>200,000,000,000 FTINU</td>
                          </tr>
                          <tr>
                            <td>Staking in Circulation (20%)</td>
                            <td>200,000,000,000 FTINU</td>
                          </tr>
                          <tr>
                            <td>Private sales (50%)</td>
                            <td>500,000,000,000 FTINU</td>
                          </tr>
                      </tbody>
                      </table>
                  </div>
              </div>
            </div>
          </div>
          
        </section>

        

        <section className="pb_50">
          <div className="container">
            <div className="sm_container">
              <div className="Package_inner">
                <h4><strong>Tip!</strong></h4>
                <hr />
                <p className="mb-0">Cryptocurrency are all on the moon mission now, FTINU is an opportunity for the new year. FTINU price depends on top 20 cryptocurrency, the altcoin season has began, HODL your FTINU and take maximum benifit.</p>
              </div>
            </div>
          </div>
        </section>

       
        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }
  
  export default withRouter(withStyles(useStyles)(withSnackbar(Home)));