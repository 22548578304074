import React, { Component } from 'react';

class Header extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      return (
        <header>
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4"></div>
            <div className="col-md-4 col-sm-4 col-12">
              <a href="/">
                <img className="logo" src="img/logo.png" alt="" />
              </a>
            </div>
            <div className="col-md-4 col-sm-4 col-12 text-right">
             
            </div>
          </div>
        </div>
      </header>
      );
    }
  }

  export default Header;